import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import logo from "./logo.png";
import logoSmall from "./logo.png";
import chip1 from './chip1.gif';
import chip2 from './chip2.gif';
import chip3 from './chip3.gif';

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "./candy-machine";

const ConnectButton = styled(WalletDialogButton)`
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: center;
`;

const CounterText = styled.span``; // add your styles here m8

const MintRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  text-align: center;
`;

const MintButton = styled(Button)`
  align-items: center;
  justify-content: center;
  background: #00E6FF!important;
`; // add your styles here

const MintButtonText = styled.div`
  padding: 5px 50px;
`;


const Spacer = styled.div`
  padding: 5rem;
`;

const SmallSpacer = styled.div`
  padding: .75rem;
`;

const Chip = styled.img`
  max-width: 200px;
  max-height: 200px;
  border-radius: 25px;
  padding: .75rem;
`;

const Samples = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  max-width: 400px;
  max-height: 400px;
  @media only screen and (max-width: 600px) {
    max-width: 250px;
    max-height: 250px;
  }
`;

const Text = styled.div`
  max-width: 500px;
`;
// Deploy
export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
  discordInvite: String;
}

const Home = (props: HomeProps) => {
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [chipsRemaining, setChipsRemaining] = useState<number>(); // number of items remaining in the contract
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); //  true when user got to press MINT

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });
  const [didMint, setDidMint] = useState(false);

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        const { itemsRemaining } = await getCandyMachineState(
          wallet as anchor.Wallet,
          props.candyMachineId,
          props.connection
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
          setDidMint(true);
          setChipsRemaining(itemsRemaining);
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      setIsMinting(false);
    }
  };

  // check if the viewport width is narrow
  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  let isMobile: boolean = (width <= 768);

  useEffect(() => {
    (async () => {
      if (!wallet) return;

      const { candyMachine, goLiveDate, itemsRemaining } =
        await getCandyMachineState(
          wallet as anchor.Wallet,
          props.candyMachineId,
          props.connection
        );
      setChipsRemaining(itemsRemaining);
      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  }, [wallet, props.candyMachineId, props.connection]);

  return (
    <MintRoot>
      {!isMobile ? <Logo src={logo} alt="logo" /> : <Logo src={logoSmall} alt="gorl" />}
      {!isSoldOut ? (
        <h1 className="font-link">Minting is live!</h1>
      ) : (
        <h1 className="font-link">Minting is over</h1>
      )}
      {!isMobile && (
        <>
          <Samples>
            {<Chip src={chip1} alt="chip1" />}
            {<Chip src={chip2} alt="chip2" />}
            {<Chip src={chip3} alt="chip3" />}
          </Samples>
        </>
      )}
      {wallet && !isSoldOut && (
        <Text>
          <p className="font-link">
            1250 unique generative Blue Chips. Secured by the
            Solana blockchain, stored on Arweave, implemented with candy machine and metaplex.
            The only real Blue Chip.
          </p>
        </Text>
      )}

      {wallet && (
        <p className="font-link">
          Remaining amount: {(chipsRemaining || 0).toLocaleString()} / 1250
        </p>
      )}
      {wallet ? (
        <p className="font-link">Mint: 0.25 SOL</p>
      ) : (
        <h2 className="font-link">Connect wallet to mint a Blue Chip. For the gripto alfa.</h2>
      )}
      {didMint ? (
        <h3>Your secret discord invite: {props.discordInvite} DO NOT SHARE OR LOSE THIS! We'll be using GRAPE once mint is over, but for now this is your ticket in.</h3>
      ) : (
        <h3>Mint to get your secret discord link.</h3>
      )}
      {!wallet ? (
        <ConnectButton>
          <h3 className="font-link">Connect Wallet</h3>
        </ConnectButton>
      ) : (
        <MintButton
          disabled={isSoldOut || isMinting || !isActive}
          onClick={onMint}
          variant="contained"
        >
          {isSoldOut ? (
            <MintButtonText className="font-link">SOLD OUT</MintButtonText>
          ) : isActive ? (
            isMinting ? (
              <CircularProgress />
            ) : (
              <MintButtonText className="font-link">MINT</MintButtonText>
            )
          ) : (
            <Countdown
              date={startDate}
              onMount={({ completed }) => completed && setIsActive(true)}
              onComplete={() => setIsActive(true)}
              renderer={renderCounter}
            />
          )}
        </MintButton>
      )}

    {isMobile && (
        <>
          <SmallSpacer/>
          <Samples>
            {<Chip src={chip1} alt="chip1" />}
            {<Chip src={chip2} alt="chip2" />}
            {<Chip src={chip3} alt="chip3" />}
          </Samples>
        </>
      )}

      <Spacer/>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </MintRoot>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {days} days, {hours} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;
